@import 'src/styles/variables';

$break: 1200px;

.wrapper {
	max-height: 100%;
	padding: 0 $padding;
	overflow-y: auto;

	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}

	@include media('>=laptop') {
		flex: 0 0 190px;
		padding: 0 0 $padding * 2 0;
		border-top: 2px solid $line;
		background-color: white;
	}

	@include media('>=#{$break}') {
		flex: 0 0 230px;
	}

	&::after {
		content: '';
		display: block;
		height: 50px;
	}
}

.title {
	margin: 30px 0 5px;
	padding: 0 $padding * 2;
	font-size: 20px;
	user-select: none;

	@include media('<#{$break}') {
		padding-left: $padding;
		padding-right: $padding;
	}

	@include media('<laptop') {
		margin: 40px 0 25px;
		padding: 0;
		font-size: 25px;
		font-weight: 600;
		text-align: center;
	}
}

.error {
	padding: 0 $padding * 2;
	color: $red;
	font-size: 13px;
}

.link {
	display: flex;
	align-items: center;
	position: relative;
	height: 85px;
	margin-bottom: 2px;
	padding: 0 ($padding * 2 + 10px) 0 $padding;

	color: $darkGrey;
	font-size: 18px;
	line-height: 1.3;
	user-select: none;
	background-color: white;

	@include media('>=laptop') {
		height: 50px;
		margin: 0;
		padding: 0 $padding;
		font-size: 13px;
		box-shadow: none;
	}

	@include media('>=#{$break}') {
		padding: 0 $padding * 2;
	}

	@include mouse {
		&:hover {
			color: black;
		}
	}

	&.active {
		color: $primary;
		font-weight: 600;
		pointer-events: none;
	}

	@include media('<laptop') {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: $padding;
			bottom: 0;
			width: 0;
			height: 0;
			margin: auto;
			border: 5px solid transparent;
			border-left: 8px solid $primary;
		}
	}
}

.picto {
	position: relative;
	flex: 0 0 50px;
	height: 50px;
	margin-right: 30px;
	line-height: 1;
	fill: $primary;
	transition-duration: $duration;
	transition-property: fill, stroke;

	@include media('>=laptop') {
		flex: 0 0 30px;
		height: 30px;
		margin-right: 15px;
		stroke: $primary;
		stroke-width: 0.1px;
	}

	.active & {
		fill: white;
		stroke: white;
	}

	svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}
